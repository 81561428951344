import { defineStore } from 'pinia';
import { AXIOS_OPTIONS, getAvgTimeToQuoteString } from '@/store/utils';

export interface CurrentProperty {
    pk: number;
}

export const usePropertiesTimeToQuoteStore = defineStore('properties', {
    state: () => ({
        current: null as CurrentProperty | null,
        avgTimeToQuote: null as string | null,
    }),
    getters: {},
    actions: {
        async getCurrentProperty() {
            // We check if we are in the development environment (localhost) and return prematurely
            // to avoid a call to es-site
            // TODO: IDR-475 - move this to Axios Interceptor.
            if (process.env.NODE_ENV === 'development') return;

            const response = await this.$nuxt?.$axios.get('/market/consumers/api/v1/properties/current/');
            this.current = response?.data;
        },
        async getTimeToQuote(pk: number) {
            try {
                const response = await this.$nuxt.$axios.get(
                    `/market/consumers/api/v1/properties/${pk}/time-to-quote/`,
                    {
                        ...AXIOS_OPTIONS,
                    },
                );
                return response;
            } catch (error) {
                return null;
            }
        },
        async getAvgTimeToQuote() {
            await this.getCurrentProperty();
            if (!this.current) {
                this.avgTimeToQuote = 'a few days';
                return;
            }
            const response = await this.getTimeToQuote(this.current.pk);
            // Converts numerical time-to-quote data to string representation.
            // NOTE: If we ever need the numerical representation, the transformation can be moved to a getter.
            this.avgTimeToQuote = getAvgTimeToQuoteString(response?.data);
        },
    },
});
