import Cookies from 'js-cookie';
import { useContext } from '@nuxtjs/composition-api';

const activated = [];

/**
 * Client side check to determine if a particular feature variant is enabled.
 *
 * Note that this method does not have information about the default variant configured on the server.
 * There may be no cookie set when the flag is set to the server default. To get correct results even in
 * this case, ensure the result of this defaults to the server default variant when no cookie is found.
 * I.e., if you would like the given variant to default to true, pass that as the final argument.
 *
 * @param {string} featureId the feature identifier
 * @param {string} variantId the variant identifier
 * @param {boolean} [defaultValue=false] optional parameter that can change the default return value
 */

export function isFeatureEnabled(featureId, variantId, defaultValue = false) {
    if (!process.client) {
        // We can't support server-side feature flags
        return false;
    }

    const { $cookies, $axios, $config } = useContext();
    const csrfToken = $cookies?.get('csrftoken');

    if (!activated.includes(featureId)) {
        activated.push(featureId);
        $axios.get(`${$config.ES_DOMAIN}/vwo/activate/?feature_id=${featureId}`, {
            headers: { 'Content-Type': 'application/json', 'X-CSRFToken': csrfToken },
            withCredentials: true,
        });
    }

    const cookieValue = Cookies.get(featureId);
    if (cookieValue === variantId) {
        return true;
    }
    // If the cookie is set to some other value, we have to assume that its another feature variant
    if (cookieValue !== undefined) {
        return false;
    }
    return defaultValue;
}

export function track($cookies, $config, $axios, goalId) {
    const csrfToken = $cookies?.get('csrftoken');

    $axios?.get(`${$config.ES_DOMAIN}/vwo/track/?goal_id=${goalId}`, {
        headers: { 'Content-Type': 'application/json', 'X-CSRFToken': csrfToken },
        withCredentials: true,
    });
}
