/**
 * Refresh an expired ID token by redirecting the user to Flash UI.
 */
import { Context } from '@nuxt/types';
import { checkFlashIdToken, getFlashTokenClaims, TokenStatus } from '~/middleware/flash-refresh';

export default async ({ $axios, $config, $cookies, redirect, route }: Context) => {
    const idToken = $cookies.get('flash.id_token');
    const tokenStatus = checkFlashIdToken(idToken);
    // Check if we are in development to avoid a call to flash profiles.
    if (process.env.NODE_ENV === 'development') {
        return;
    }
    if (route.path !== '/onboarding/start/VerifyComplete/' && tokenStatus === TokenStatus.NOT_VERIFIED) {
        const flashTokenClaims = getFlashTokenClaims(idToken);
        const result = await $axios.get(
            `${$config.FLASH_PROFILES_API_DOMAIN}/users/${flashTokenClaims[`custom:flash_user_id`]}/`,
            {
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${idToken}` },
            },
        );
        if (result.status === 200 && result.data.emailVerified !== true) {
            redirect('/onboarding/start/VerifyComplete/');
        }
    }
};
