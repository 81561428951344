import * as Sentry from '@sentry/browser';
import axios from 'axios';

const axiosConfig = {
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'x-csrftoken',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
};
const http = axios.create(axiosConfig);

// Referral.js from https://app.referralrock.com/clientadmin/integrations/
const head = document.head || document.getElementsByTagName('head')[0];
const src = '//energysageinc.referralrock.com/ReferralSdk/referral.js';
const transactionKey = '83f38b34-ed6e-4620-82a2-028e38c2a0a7';
window.referralJS = window.referralJS !== null && window.referralJS !== undefined ? window.referralJS : {};
window.referralJS.scriptConfig = { parameters: { src, transactionKey } };
const rrScript = document.createElement('script');
rrScript.async = 1;
rrScript.src = `${src}?referrer=${encodeURIComponent(window.location.origin + window.location.pathname).replace(
    /[!'()*]/g,
    escape,
)}`;
rrScript.id = 'RR_DIVID_V5';
rrScript.setAttribute('transactionKey', transactionKey);
head.appendChild(rrScript);

const referralRock = {
    logRegistration: ({ firstname, email }) => {
        // Fail if first name or email are missing
        if (!firstname || !email) {
            Sentry.captureMessage(
                'Missing required first name or email. Unable to set Referral Rock conversion',
                'info',
            );
            return;
        }

        // Check for the domain, and either a referral ID cookie or a partner code cookie
        const rrWCIDCookie = document.cookie.match('(^|;)\\s*RR_WCID\\s*=\\s*([^;]+)')?.pop() || '';
        const rrPartnerCodeCookie = document.cookie.match('(^|;)\\s*rr-rc\\s*=\\s*([^;]+)')?.pop() || '';
        const domainMatcher = document.domain.match(/\./g);
        let cookieDomain = document.domain;
        if (domainMatcher?.length) {
            // handle cookie both on *.energysage.com and localhost for local dev
            cookieDomain = `${document.domain
                .split('.')
                .slice(domainMatcher.length > 1 ? domainMatcher.length - 1 : 0)
                .join('.')}`;
        }

        // https://api.referralrock.com/Help/Api/POST-api-referrals
        const parameters = {
            firstname,
            email,
            customText1Name: 'Browser Referrer URL',
            customText1Value: document.referrer,
            customText2Name: 'Conversion page URL',
            customText2Value: window.location.href,
            customText3Name: 'Partner RC referral',
            customText3Value: rrPartnerCodeCookie ? 'True' : 'False',
        };

        // Allow subscription to complete, catching errors silently
        if (rrWCIDCookie) {
            // Referral not originating from a partner landing page, i.e. from an ES user's custom referral link
            try {
                // https://app.referralrock.com/clientadmin/integrations/scriptsbeta/?event=Conversion&version=5
                window.referralJS =
                    window.referralJS !== null && window.referralJS !== undefined ? window.referralJS : {};
                window.referralJS.conversion = {
                    debug: 'false',
                    parameters,
                    onConversion: () => {
                        // Delete the referral rock cookie, so the script won't get called again on reload
                        // or if a different user uses this browser
                        document.cookie =
                            'RR_WCID=;expires =Thu, 01-Jan-1970 00:00:01 GMT;max-age=0;' +
                            `domain=${cookieDomain};path=/;SameSite=null`;
                    },
                };
            } catch (e) {
                Sentry.captureMessage(new Error(e), 'info');
            }
        } else if (rrPartnerCodeCookie) {
            // Users who were referred by a partner with an rc code, as opposed to clicking a user's referral link,
            // don't have RR_WCID set. Manually call the API to create a referral, rather than using the conversion
            // script provided by Referral Rock.
            const queryString = new URLSearchParams();
            Object.entries(parameters).forEach(([name, value]) => {
                queryString.append(name, value);
            });
            // Need this extra parameter to create the referral manually, to associate with correct referring member
            queryString.append('referralCode', rrPartnerCodeCookie);

            http.post('/refer-a-friend/referral/', queryString);
            // Delete the referral rock partner cookie, so the script won't get called again on reload
            // or if a different user uses this browser
            document.cookie =
                'rr-rc=;expires =Thu, 01-Jan-1970 00:00:01 GMT;max-age=0;' +
                `domain=${cookieDomain};path=/;SameSite=null`;
        }
    },
};

/**
 * Referral Tracking
 * Currently uses Referral Rock and should only be triggered after a successful subscription
 * https://help.referralrock.com/article/8en6jdmki7-conversion-tracking-java-script
 *
 */
export default referralRock;
