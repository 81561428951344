
import { useWizardStore } from '~/store/wizard';

export default {
    name: 'ErrorLayout',
    props: {
        error: {
            type: Object,
            required: true,
        },
    },
    computed: {
        errorCode() {
            return String(this.error.statusCode);
        },
    },
    mounted() {
        const wizardStore = useWizardStore();
        wizardStore.showLoadingSpinner = false;
    },
};
